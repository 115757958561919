import React, { useState, useEffect, useRef, useCallback } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import { toPng } from 'html-to-image';
import '../css/ReservoirChartComponent.css';
import logo from '../iwmi_dws_logo.png';

const ReservoirChartComponent = ({ analysisType, params, startDate, endDate }) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const chartRef = useRef(null);

    const fetchData = useCallback(async () => {
        try {
            const queryParams = new URLSearchParams({
                percent_invalid: params.percentInvalid,
                start_date: startDate,
                end_date: endDate
            });

            switch (analysisType) {
                case 'Water Area Timeseries':
                    queryParams.append('data_type', 'water_area_timeseries');
                    queryParams.append('reservoir_name', params.selectedOption);
                    break;
                case 'Aggregated Water Area Timeseries':
                    queryParams.append('data_type', 'aggregated_water_area_timeseries');
                    queryParams.append('reservoir_name', params.selectedOption);
                    break;
                case 'DEA Water Area Timeseries by ID':
                    queryParams.append('data_type', 'dea_water_area_timeseries_id');
                    queryParams.append('original_id', params.selectedOption);
                    break;
                case 'Aggregated Water Area Timeseries by Channel':
                    queryParams.append('data_type', 'aggregated_water_area_timeseries_by_channel');
                    queryParams.append('swat_channel', params.selectedOption);
                    break;
                case 'Yearly Aggregated Timeseries by Channel':
                    queryParams.append('data_type', 'yearly_aggregated_timeseries_channel');
                    queryParams.append('swat_channel', params.selectedOption);
                    break;
                default:
                    throw new Error('Unknown analysis type');
            }

            const response = await fetch(`${process.env.REACT_APP_API_URL}/reservoir_data?${queryParams.toString()}`, {
                method: 'GET'
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const result = await response.json();
            setData(result);
        } catch (error) {
            console.error('Error fetching data:', error);
            setError(error);
        }
    }, [analysisType, params, startDate, endDate]);

    useEffect(() => {
        if (analysisType && params.selectedOption) {
            fetchData();
        }
    }, [analysisType, params, startDate, endDate, fetchData]);

    const processDataForChart = (data) => {
        return data.map(d => ({
            ...d,
            date: new Date(d.date).valueOf()
        }));
    };

    const processedData = data ? processDataForChart(data) : [];

    const handleExport = () => {
        if (chartRef.current) {
            toPng(chartRef.current)
                .then((dataUrl) => {
                    const link = document.createElement('a');
                    link.download = 'chart.png';
                    link.href = dataUrl;
                    link.click();
                })
                .catch((error) => {
                    console.error('Error exporting chart:', error);
                });
        }
    };

    const getYAxisLabel = () => {
        switch (analysisType) {
            case 'Water Area Timeseries':
                return 'Water Area (ha)';
            case 'Aggregated Water Area Timeseries':
            case 'DEA Water Area Timeseries by ID':
                return 'Avg Water Area (ha)';
            case 'Aggregated Water Area Timeseries by Channel':
            case 'Yearly Aggregated Timeseries by Channel':
                return 'Total Water Area (ha)';
            default:
                return '';
        }
    };

    const getYAxisKey = () => {
        switch (analysisType) {
            case 'Water Area Timeseries':
                return 'water_area_ha';
            case 'Aggregated Water Area Timeseries':
            case 'DEA Water Area Timeseries by ID':
                return 'avg_water_area_ha';
            case 'Aggregated Water Area Timeseries by Channel':
            case 'Yearly Aggregated Timeseries by Channel':
                return 'total_water_area_ha';
            default:
                return '';
        }
    };

    return (
        <div className="parent-container">
        <div className="chart-container">
            {error && <p>Error: {error.message}</p>}
            {data ? (
                <div>
                    <p className="fs-4">
                        {analysisType} for site: <span className="option-id">{params.selectedOption}</span>
                    </p>
                    <div className="y-axis-input-container">
                        <div className="button-container">
                            <button onClick={handleExport}>Export Chart</button>
                        </div>
                    </div>
                    <div ref={chartRef} style={{height: "60vh", width: "80vw"}}>
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart data={processedData}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                    dataKey="date"
                                    scale="time"
                                    type="number"
                                    domain={['auto', 'auto']}
                                    tickFormatter={(unixTime) => moment(unixTime).format('YYYY-MM-DD')}
                                    tick={{ fontSize: '14px', fontFamily: 'Arial' }}
                                />
                                <YAxis
                                    label={{ value: getYAxisLabel(), angle: -90, position: 'insideLeft', style: { fontSize: '12px' } }}
                                    type="number"
                                    domain={['auto', 'auto']}
                                    tick={{ fontSize: '12px', fontFamily: 'Arial' }}
                                />
                                <Tooltip />
                                <Legend />
                                <Line name="Water Area" type="monotone" dataKey={getYAxisKey()} stroke="#000000" strokeWidth={3} dot={false} />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                    <img src={logo} alt="IWMI DWS Logo" className="logo" />
                </div>
            ) : (
                <div className="position-absolute w-100 h-100 d-flex flex-column align-items-center bg-white justify-content-center">
                                    Loading...
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )}
        </div>
        </div>
    );
};

export default ReservoirChartComponent;
