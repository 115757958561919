import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Brush, Scatter, ComposedChart, Area, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';
import { getToken } from '../components/auth';
import moment from 'moment';
import { toPng } from 'html-to-image';
import '../css/RealtimeChartComponent.css';
import logo from '../iwmi_dws_logo.png';
import { MDBBtn } from 'mdb-react-ui-kit';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const formattedDate = moment(label).format('YYYY-MM-DD');

    return (
      <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '5px', fontSize: '13px' }}>
        <p style={{ margin: 0, fontWeight: 'bold' }}>{formattedDate}</p>
        {payload.map((entry, index) => (
          <p key={`item-${index}`} style={{ margin: 0, color: entry.color }}>
            {entry.name}: {entry.value}
          </p>
        ))}
      </div>
    );
  }

  return null;
};

const EFlowChartExport = ({ riverCode, startDate, endDate, description, siteName }) => {
  const [data, setData] = useState(null);
  const [fishActivityData, setFishActivityData] = useState([]);
  const [error, setError] = useState(null);
  const [visibleSeries, setVisibleSeries] = useState({
    SWAT_present_flow: true,
    Natural_Flow: true,
    Eflow: true,
    water_availability: true,
    surplus: true,
    deficit: true,
    pc10: true,
    pc50: true,
    pc90: true,
    percentile_0_1: false,
    percentile_1: false,
    percentile_5: false,
    percentile_10: false,
    percentile_15: false,
    percentile_20: false,
    percentile_30: false,
    percentile_40: false,
    percentile_50: false,
    percentile_60: false,
    percentile_70: false,
    percentile_80: false,
    percentile_85: false,
    percentile_90: false,
    percentile_95: false,
    percentile_99: false,
    percentile_99_9: false,
    fish_activity: false
  });

  const chartRef = useRef(null);
  const [isWaterAvailabilityVisible, setIsWaterAvailabilityVisible] = useState(true);

  const percentileLabels = {
    percentile_0_1: '0.1%',
    percentile_1: '1%',
    percentile_5: '5%',
    percentile_10: '10%',
    percentile_15: '15%',
    percentile_20: '20%',
    percentile_30: '30%',
    percentile_40: '40%',
    percentile_50: '50%',
    percentile_60: '60%',
    percentile_70: '70%',
    percentile_80: '80%',
    percentile_85: '85%',
    percentile_90: '90%',
    percentile_95: '95%',
    percentile_99: '99%',
    percentile_99_9: '99.9%'  
  };

  const fetchData = useCallback(async () => {
    try {
      const token = await getToken();

      const queryParams = new URLSearchParams({
        river_code: riverCode,
        start_date: startDate,
        end_date: endDate
      }).toString();

      const response = await fetch(`${process.env.REACT_APP_API_URL}/eflow_chart?${queryParams}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      setData(result);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error);
    }
  }, [riverCode, startDate, endDate]);

  const fetchFishActivityData = useCallback(async () => {
    try {
      const token = await getToken();
      const response = await fetch(`${process.env.REACT_APP_API_URL}/fishtrac_activity`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      setFishActivityData(result.map(d => ({
        ...d,
        date: new Date(d.date).valueOf()
      })));
    } catch (error) {
      console.error('Error fetching fish activity data:', error);
      setError(error);
    }
  }, []);

  useEffect(() => {
    if (riverCode && startDate && endDate) {
      fetchData();
      fetchFishActivityData();
    }
  }, [riverCode, startDate, endDate, fetchData, fetchFishActivityData]);

  const processDataForChart = (data) => {
    return data.map(d => ({
      ...d,
      date: new Date(d.date).valueOf()
    }));
  };

  const processedData = data ? processDataForChart(data) : [];

  const handleToggleSeries = (series) => {
    setVisibleSeries(prevState => ({ ...prevState, [series]: !prevState[series] }));
  };

  const handleToggleWaterAvailability = () => {
    setVisibleSeries(prevState => ({
      ...prevState,
      water_availability: !prevState.water_availability,
      deficit: !prevState.deficit,
      surplus: !prevState.surplus,
    }));
    setIsWaterAvailabilityVisible(prevState => !prevState);
  };

  const renderLegend = (props) => {
    const { payload } = props;
  
    const percentilePayload = payload.filter(entry => entry.dataKey.includes('percentile') || entry.dataKey === 'min' || entry.dataKey === 'max');
    const waterAvailabilityPayload = payload.filter(entry => entry.dataKey === 'surplus' || entry.dataKey === 'deficit' || entry.dataKey === 'water_availability' || entry.dataKey.includes('pc'));
  
    return (
      <div style={{
        border: '1px solid lightgrey',
        padding: '10px',
        borderRadius: '2px',
        display: 'inline-block'
      }}>
        <div style={{ fontSize: '14px', fontWeight: 'bold', marginBottom: '5px' }}>
          Legend:
        </div>
        <div className="custom-legend" style={{ display: 'flex', fontSize: '12px', marginBottom: '10px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', marginRight: '20px' }}>
            {
              payload.map((entry, index) => {
                if (percentilePayload.includes(entry) || waterAvailabilityPayload.includes(entry)) return null;
                const label = percentileLabels[entry.dataKey] || entry.dataKey.replace(/_/g, ' ');
                const isSquare = entry.dataKey.includes('percentile') || entry.dataKey === 'min' || entry.dataKey === 'max' || entry.dataKey === 'water_availability' || entry.dataKey === 'surplus' || entry.dataKey === 'deficit';
                const isLine = entry.dataKey.includes('pc'); // This identifies the confidence intervals
                return (
                  <span key={`item-${index}`} style={{ marginBottom: '5px', color: entry.color, display: 'inline-flex', alignItems: 'center', fontSize: '12px' }}>
                    {entry.dataKey === 'fish_activity' ? (
                      <svg width="10" height="10" style={{ marginRight: 5 }}>
                        <line x1="5" y1="0" x2="5" y2="10" stroke={entry.color} strokeWidth="2" />
                        <line x1="0" y1="5" x2="10" y2="5" stroke={entry.color} strokeWidth="2" />
                      </svg>
                    ) : entry.dataKey === 'pc50' ? (
                      <svg width="20" height="10" style={{ marginRight: 5 }}>
                        <line x1="0" y1="5" x2="20" y2="5" stroke={entry.color} strokeWidth="2" />
                      </svg>
                    )
                    : isSquare ? (
                      <svg width="10" height="10" style={{ marginRight: 5 }}>
                        <rect width="10" height="10" fill={entry.color} />
                      </svg>
                    ) : isLine ? null : ( // Ensures confidence intervals are not rendered here
                      <svg width="20" height="10" style={{ marginRight: 5 }}>
                        <line x1="0" y1="5" x2="20" y2="5" stroke={entry.color} strokeWidth="2" />
                      </svg>
                    )}
                    {label}
                  </span>
                );
              })
            }
          </div>

          {/* Water Availability Column */}
          {waterAvailabilityPayload.length > 0 && (
            <div style={{ borderLeft: '1px solid black', marginRight: '20px', paddingLeft: '10px', display: 'flex', flexDirection: 'column' }}>
              <div style={{ fontSize: '12px', fontWeight: 'bold', marginBottom: '5px' }}>
                Water Availability:
              </div>
              {
                waterAvailabilityPayload.map((entry, index) => {
                  const label = percentileLabels[entry.dataKey] || entry.dataKey.replace(/_/g, ' ');
                  const isLine = entry.dataKey.includes('pc'); // Check for confidence intervals
                  if (isLine) return null; // Exclude confidence intervals from this column
                  return (
                    <span key={`item-${index}`} style={{ marginBottom: '5px', color: entry.color, display: 'inline-flex', alignItems: 'center', fontSize: '12px' }}>
                      <svg width="10" height="10" style={{ marginRight: 5 }}>
                        <rect width="10" height="10" fill={entry.color} />
                      </svg>
                      {label}
                    </span>
                  );
                })
              }
            </div>
          )}  

          {/* Percentiles Column */}
          {percentilePayload.length > 0 && (
            <div style={{ borderLeft: '1px solid black', paddingLeft: '10px', display: 'flex', flexDirection: 'column' }}>
              <div style={{ fontSize: '12px', fontWeight: 'bold', marginBottom: '5px' }}>
                Naturalised Flow Percentiles:
              </div>
              {
                percentilePayload.map((entry, index) => {
                  const label = percentileLabels[entry.dataKey] || entry.dataKey.replace(/_/g, ' ');
                  const isSquare = entry.dataKey.includes('percentile') || entry.dataKey === 'min' || entry.dataKey === 'max';
                  return (
                    <span key={`item-${index}`} style={{ marginBottom: '5px', color: entry.color, display: 'inline-flex', alignItems: 'center', fontSize: '12px' }}>
                      {isSquare ? (
                        <svg width="10" height="10" style={{ marginRight: 5 }}>
                          <rect width="10" height="10" fill={entry.color} />
                        </svg>
                      ) : (
                        <svg width="20" height="10" style={{ marginRight: 5 }}>
                          <line x1="0" y1="5" x2="20" y2="5" stroke={entry.color} strokeWidth="2" />
                        </svg>
                      )}
                      {label}
                    </span>
                  );
                })
              }
            </div>
          )}

          {/* Confidence Intervals Column */}
          {payload.some(entry => entry.dataKey.includes('pc')) && (
            <div style={{ borderLeft: '1px solid black', paddingLeft: '10px', display: 'flex', flexDirection: 'column' }}>
              <div style={{ fontSize: '12px', fontWeight: 'bold', marginBottom: '5px' }}>
                Confidence Intervals:
              </div>
              {
                payload.map((entry, index) => {
                  if (!entry.dataKey.includes('pc')) return null; // Ensures only confidence intervals are placed here
                  const label = percentileLabels[entry.dataKey] || entry.dataKey.replace(/_/g, ' ');
                  return (
                    <span key={`item-${index}`} style={{ marginBottom: '5px', color: entry.color, display: 'inline-flex', alignItems: 'center', fontSize: '12px' }}>
                      <svg width="20" height="10" style={{ marginRight: 5 }}>
                        <line x1="0" y1="5" x2="20" y2="5" stroke={entry.color} strokeWidth="2" strokeDasharray="5 5" />
                      </svg>
                      {label}
                    </span>
                  );
                })
              }
            </div>
          )}
        </div>
      </div>
    );
  };
  
  
  const handleExport = () => {
    if (chartRef.current) {
      toPng(chartRef.current)
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.download = 'chart.png';
          link.href = dataUrl;
          link.click();
        })
        .catch((error) => {
          console.error('Error exporting chart:', error);
        });
    }
  };

  const combinedData = processedData.map(d => {
    const fishActivity = fishActivityData.find(f => f.date === d.date);
    return {
      ...d,
      fish_activity: fishActivity ? fishActivity.Activity_per_minute : null
    };
  });
  // Get the current date
  const currentDate = moment();

  // Calculate the last day of the current month
  const today = currentDate.endOf('month').valueOf();
  return (
    <div className="chart-container" style={{height: "600px", width: "800px", backgroundColor: 'white'}}>
      {error && <p>Error: {error.message}</p>}
      {data ? (
        <div>
          <div className="button-container" style={{ display: 'none' }}>
          <MDBBtn onClick={handleExport}>Export Chart</MDBBtn>
            <MDBBtn
              className={`'mx-2' ${isWaterAvailabilityVisible ? 'active' : ''}`}
              onClick={handleToggleWaterAvailability}
              color={'primary'}
            >
              Water Availability
            </MDBBtn>
            {Object.keys(visibleSeries).map(series => (
              (series !== 'deficit' && series !== 'surplus' && series !== 'water_availability') && (
                <MDBBtn
                  key={series}
                  className={`'mx-2' ${visibleSeries[series] ? 'active' : ''}`}
                  onClick={() => handleToggleSeries(series)}
                  color={'secondary'}
                >
                  {series.includes('percentile') ? percentileLabels[series] : series.replace(/_/g, ' ')}
                </MDBBtn>
              )
            ))}
          </div>
          <div ref={chartRef} style={{height: "570px", width: "770px", backgroundColor: 'white'}}>
            <ResponsiveContainer>
              <ComposedChart data={combinedData} accessibilityLayer>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="date"
                  scale="time"
                  type="number"
                  domain={['auto', 'auto']}
                  tickFormatter={(unixTime) => moment(unixTime).format('YYYY-MM-DD')}
                  tick={{ fontSize: '12px', fontFamily: 'Arial' }}
                />
                <YAxis
                  label={{ value: 'Monthly Mean Discharge (m³/s)', angle: -90, position: 'insideLeft', style: { fontSize: '12px' } }}
                  type="number"
                  domain={['auto', 'auto']}
                  tick={{ fontSize: '12px', fontFamily: 'Arial' }}
                />
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  label={{ value: 'Fish Activity (per minute)', angle: -90, position: 'insideRight', style: { fontSize: '12px' } }}
                  type="number"
                  domain={['auto', 'auto']}
                  tick={{ fontSize: '12px', fontFamily: 'Arial' }}
                />

                <Tooltip content={<CustomTooltip />} />
                <Legend content={renderLegend} />
                {visibleSeries.surplus && <Area connectNulls name="Availability: Surplus" type="monotone" dataKey="surplus" stroke="#1E90FF" fill="#1E90FF" fillOpacity={1} />}
                {visibleSeries.deficit && <Area connectNulls name="Availability: Deficit" type="monotone" dataKey="deficit" stroke="#FF4500" fill="#FF4500" fillOpacity={1} />}
                {visibleSeries.Natural_Flow && <Line connectNulls name="Natural Flow" type="monotone" dataKey="Natural_Flow" stroke="darkgreen" strokeWidth={2} dot={true} />}
                {visibleSeries.water_availability && <Area connectNulls name="Availability" type="monotone" dataKey="water_availability" stroke="#fff" fill="#fff" fillOpacity={1} />}
                {visibleSeries.SWAT_present_flow && <Line connectNulls name="SWAT Present Day" type="monotone" dataKey="SWAT_present_flow" stroke="#000" strokeWidth={4} dot={true} />}
                {visibleSeries.Eflow && <Line connectNulls name="Eflow" type="monotone" dataKey="Eflow" stroke="darkred" strokeWidth={4} dot={true} strokeDasharray="5 5" />}
                {visibleSeries.pc10 && (
                  <Line
                    connectNulls
                    name="PC10"
                    type="monotone"
                    dataKey="pc10"
                    stroke="#696969" // Dark grey for the lower bound
                    strokeWidth={3}
                    dot={false}
                    strokeDasharray="8 4" // Thicker and more prominent dashes
                  />
                )}
                {visibleSeries.pc50 && (
                  <Line
                    connectNulls
                    name="PC50"
                    type="monotone"
                    dataKey="pc50"
                    stroke="#A9A9A9" // Medium grey for the median
                    strokeWidth={3}
                    dot={false}
                    strokeDasharray="6 3" // Medium dashes
                  />
                )}
                {visibleSeries.pc90 && (
                  <Line
                    connectNulls
                    name="PC90"
                    type="monotone"
                    dataKey="pc90"
                    stroke="#D3D3D3" // Light grey for the upper bound
                    strokeWidth={3}
                    dot={false}
                    strokeDasharray="4 2" // Shorter dashes for the lightest line
                  />
                )}
                {visibleSeries["percentile_99_9"] && <Area connectNulls stackId="1" name="99.9%" type="monotone" dataKey="percentile_99_9" stroke="#FF4500" fill="#FF4500" fillOpacity={0.30} />}
                {visibleSeries["percentile_99"] && <Area connectNulls stackId="1" name="99%" type="monotone" dataKey="percentile_99" stroke="#FF6347" fill="#FF6347" fillOpacity={0.30} />}
                {visibleSeries["percentile_95"] && <Area connectNulls stackId="1" name="95%" type="monotone" dataKey="percentile_95" stroke="#FF7F50" fill="#FF7F50" fillOpacity={0.30} />}
                {visibleSeries["percentile_90"] && <Area connectNulls stackId="1" name="90%" type="monotone" dataKey="percentile_90" stroke="#FF8C00" fill="#FF8C00" fillOpacity={0.30} />}
                {visibleSeries["percentile_85"] && <Area connectNulls stackId="1" name="85%" type="monotone" dataKey="percentile_85" stroke="#FFA500" fill="#FFA500" fillOpacity={0.30} />}
                {visibleSeries["percentile_80"] && <Area connectNulls stackId="1" name="80%" type="monotone" dataKey="percentile_80" stroke="#FFD700" fill="#FFD700" fillOpacity={0.30} />}
                {visibleSeries["percentile_70"] && <Area connectNulls stackId="1" name="70%" type="monotone" dataKey="percentile_70" stroke="#FFFF00" fill="#FFFF00" fillOpacity={0.30} />}
                {visibleSeries["percentile_60"] && <Area connectNulls stackId="1" name="60%" type="monotone" dataKey="percentile_60" stroke="#ADFF2F" fill="#ADFF2F" fillOpacity={0.30} />}
                {visibleSeries["percentile_50"] && <Area connectNulls stackId="1" name="50%" type="monotone" dataKey="percentile_50" stroke="#7FFF00" fill="#7FFF00" fillOpacity={0.30} />}
                {visibleSeries["percentile_40"] && <Area connectNulls stackId="1" name="40%" type="monotone" dataKey="percentile_40" stroke="#32CD32" fill="#32CD32" fillOpacity={0.30} />}
                {visibleSeries["percentile_30"] && <Area connectNulls stackId="1" name="30%" type="monotone" dataKey="percentile_30" stroke="#00FF00" fill="#00FF00" fillOpacity={0.30} />}
                {visibleSeries["percentile_20"] && <Area connectNulls stackId="1" name="20%" type="monotone" dataKey="percentile_20" stroke="#00FA9A" fill="#00FA9A" fillOpacity={0.30} />}
                {visibleSeries["percentile_15"] && <Area connectNulls stackId="1" name="15%" type="monotone" dataKey="percentile_15" stroke="#00FF7F" fill="#00FF7F" fillOpacity={0.30} />}
                {visibleSeries["percentile_10"] && <Area connectNulls stackId="1" name="10%" type="monotone" dataKey="percentile_10" stroke="#00CED1" fill="#00CED1" fillOpacity={0.30} />}
                {visibleSeries["percentile_5"] && <Area connectNulls stackId="1" name="5%" type="monotone" dataKey="percentile_5" stroke="#4682B4" fill="#4682B4" fillOpacity={0.30} />}
                {visibleSeries["percentile_1"] && <Area connectNulls stackId="1" name="1%" type="monotone" dataKey="percentile_1" stroke="#1E90FF" fill="#1E90FF" fillOpacity={0.30} />}
                {visibleSeries["percentile_0_1"] && <Area connectNulls stackId="1" name="0.1%" type="monotone" dataKey="percentile_0_1" stroke="#00008B" fill="#00008B" fillOpacity={0.30} />}
                {visibleSeries.fish_activity && (
                  <Scatter
                    name="Fish Activity"
                    type="monotone"
                    dataKey="fish_activity"
                    yAxisId="right"
                    shape="cross"
                    fill="#8884d8"
                  /> 
                )}
                <ReferenceLine
                  x={today}
                  stroke="blue"
                  strokeDasharray="5 5"
                  strokeWidth={2}
                  label={{
                    position: 'top',
                    value: 'Historical',
                    fill: 'blue',
                    dy: 20,
                    dx: -40 // Adjust this value as needed to position the label to the left of the line
                  }}
                />
                <ReferenceLine
                  x={today}
                  stroke="blue"
                  strokeDasharray="5 5"
                  strokeWidth={2}
                  label={{
                    position: 'top',
                    value: 'Forecast',
                    fill: 'black',
                    dy: 20,
                    dx: 40 // Adjust this value as needed to position the label to the left of the line
                  }}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      ) : (
        <div className="loading-spinner">
          <div className="spinner">
            <img src={logo} alt="IWMI DWS Logo" className="logo" />
          </div>
        </div>
      )}
    </div>
  );
};

export default EFlowChartExport;
