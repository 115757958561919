import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, GeoJSON, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import '../css/MapComponent.css';
import InputSlider from 'react-input-slider';
import L from 'leaflet';
import moment from 'moment';
import _ from 'lodash'; // Import lodash

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const legend = [
  { label: "None", color: "#2c7a2c" },
  { label: "Warning", color: "#e1ad01" },
  { label: "Level 1", color: "#d4af37" },
  { label: "Level 2", color: "#ff8c00" },
  { label: "Level 3", color: "#cd3700" },
  { label: "Level 4", color: "#b22222" },
  { label: "Level 5", color: "#5c0a0a" }
];

const LegendControl = () => {
  const map = useMap();

  useEffect(() => {
    const legendControl = L.control({ position: 'bottomright' });

    legendControl.onAdd = () => {
      const div = L.DomUtil.create('div', 'info legend');
      div.innerHTML = '<h4>Alert Type</h4>';
      legend.forEach(item => {
        div.innerHTML += `<i style="background: ${item.color}"></i> ${item.label}<br>`;
      });
      return div;
    };

    legendControl.addTo(map);

    return () => {
      legendControl.remove();
    };
  }, [map]);

  return null;
};

const EFlowStatusMap = ({ dateSelected, setDateSelected }) => {
  const [geoData, setGeoData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sliderValue, setSliderValue] = useState(moment(dateSelected).diff(moment('2001-01-01'), 'months'));
  const geoJsonLayer = useRef(null);
  const previousDateRef = useRef(dateSelected);

  const fetchEflowData = async (date) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    //console.log(`Fetching data for date: ${formattedDate}`);
    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/geo_eflow_ts?selected_date=${formattedDate}`);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      setGeoData(result);
    } catch (error) {
      console.error('Error fetching eflow data:', error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchEflowData = useRef(_.debounce((date) => {
    fetchEflowData(date);
  }, 500)).current;

  useEffect(() => {
    //console.log(`Use Effect Update to Selected Date: ${dateSelected}`);
    debouncedFetchEflowData(dateSelected);
    previousDateRef.current = dateSelected;
  }, [dateSelected, debouncedFetchEflowData]);

  useEffect(() => {
    if (geoJsonLayer.current && geoData) {
      geoJsonLayer.current.clearLayers().addData(geoData);
    }
  }, [geoData]);

  const handleDateChange = ({ x }) => {
    const newDate = moment('2001-01-01').add(x, 'months').endOf('month').format('YYYY-MM-DD');
    //console.log(`Slider changed, new date: ${newDate}`);
    setSliderValue(x);
    setDateSelected(newDate);
  };

  const handleSliderDragEnd = () => {
    if (dateSelected !== previousDateRef.current) {
      previousDateRef.current = dateSelected;
    }
  };

  const pointToLayer = (feature, latlng) => {
    const color = feature.properties.color || '#3388ff';
    const markerIcon = new L.Icon({
      iconUrl: `data:image/svg+xml;base64,${btoa(`
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="12px" height="12px">
          <circle cx="12" cy="12" r="10" stroke="${color}" stroke-width="2" fill="white" />
          <path d="M3.5,9c1.4,0,2.3,1.2,3.1,2.2c0.5,0.6,0.9,1,1.4,1s0.9-0.4,1.4-1C10.2,10.2,11.1,9,12.5,9
            c1.4,0,2.3,1.2,3.1,2.2c0.5,0.6,0.9,1,1.4,1s0.9-0.4,1.4-1C18.2,10.2,19.1,9,20.5,9V7.5c-1.4,0-2.3,1.2-3.1,2.2
            c-0.5,0.6-0.9,1-1.4,1s-0.9-0.4-1.4-1C14.3,8.7,13.4,7.5,12,7.5S9.7,8.7,9.1,9.7c-0.5,0.6-0.9,1-1.4,1s-0.9-0.4-1.4-1
            C5.8,8.7,4.9,7.5,3.5,7.5V9z" fill="${color}"/>
          <path d="M3.5,14.5c1.4,0,2.3,1.2,3.1,2.2c0.5,0.6,0.9,1,1.4,1s0.9-0.4,1.4-1c0.8-1,1.7-2.2,3.1-2.2
            c1.4,0,2.3,1.2,3.1,2.2c0.5,0.6,0.9,1,1.4,1s0.9-0.4,1.4-1c0.8-1,1.7-2.2,3.1-2.2
            c1.4,0,2.3,1.2,3.1,2.2c0.5,0.6,0.9,1,1.4,1s0.9-0.4,1.4-1c0.8-1,1.7-2.2,3.1-2.2s2.3,1.2,3.1,2.2c0.5,0.6,0.9,1,1.4,1
            s0.9-0.4,1.4-1c0.8-1,1.7-2.2,3.1-2.2s2.3,1.2,3.1,2.2c0.5,0.6,0.9,1,1.4,1s0.9-0.4,1.4-1c0.8-1,1.7-2.2,3.1-2.2s2.3,1.2,3.1,2.2c0.5,0.6,0.9,1,1.4,1s0.9-0.4,1.4-1c0.8-1,1.7-2.2,3.1-2.2v-1.5c-1.4,0-2.3,1.2-3.1,2.2c-0.5,0.6-0.9,1-1.4,1s-0.9-0.4-1.4-1c-0.8-1-1.7-2.2-3.1-2.2s-2.3,1.2-3.1,2.2c-0.5,0.6-0.9,1-1.4,1s-0.9-0.4-1.4-1c-0.8-1-1.7-2.2-3.1-2.2V14.5z" fill="${color}"/>
        </svg>
      `)}`,
      iconSize: [24, 24],
      iconAnchor: [12, 24],
      popupAnchor: [0, -24],
    });
    return L.marker(latlng, { icon: markerIcon });
  };

  const onEachFeature = (feature, layer) => {
    if (feature.properties) {
      const percentage = feature.properties.Actual_Percentage;
      const percentageLabel = percentage < 0 ? "Deficit (%)" : "Surplus (%)";

      const popupContent = `
          <div className="card" style="width: 18rem;">
              <div className="card-header" style="background-color: #343a40; color: #ffffff;">
                  ${feature.properties.river_code}
              </div>
              <div className="card-body" style="font-family: Arial, sans-serif; padding: 10px;">
                  <h5 className="card-title">${feature.properties.message}</h5>
                  <p className="card-text" style="margin: 0; font-size: 14px;">
                      <strong>E-flow:</strong> ${feature.properties.Eflow}<br />
                      <strong>${percentageLabel}:</strong> ${percentage}%
                  </p>
                  <button className="btn btn-dark" style="margin-top: 10px;" onclick="alert('View chart button clicked')">View Chart</button>
              </div>
          </div>
      `;
      layer.bindPopup(popupContent);
    }
  };

  return (
    <div className="EFlowStatusMap">
      {loading && <div className="loading-spinner"></div>}
      <MapContainer center={[-23.5, 29.5]} zoom={7} className="MapComponent">
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {error && <p className="error-message">Error: {error.message}</p>}
        {geoData && (
          <GeoJSON key={dateSelected} ref={geoJsonLayer} data={geoData} pointToLayer={pointToLayer} onEachFeature={onEachFeature} />
        )}
        <LegendControl />
      </MapContainer>
      <div className="slider-container">
        <InputSlider
          axis="x"
          x={sliderValue}
          xmin={0}
          xmax={moment('2024-12-31').diff(moment('2001-01-01'), 'months')}
          xstep={1}
          onChange={handleDateChange}
          onDragEnd={handleSliderDragEnd}
          styles={{
            track: {
              backgroundColor: 'lightgrey',
              height: '4px',
              width: '100%' // Ensure the track takes full width
            },
            active: {
              backgroundColor: '#007bff',
              height: '4px',
              width: '100%' // Ensure the active track takes full width
            },
            thumb: {
              width: '20px', // Adjust the width of the thumb
              height: '20px', // Adjust the height of the thumb
              backgroundColor: '#ffffff',
              border: '2px solid #007bff',
              boxShadow: '0 0 2px rgba(0,0,0,0.2)'
            },
            sliderLabel: {
              backgroundColor: '#007bff',
              color: '#ffffff',
              padding: '2px 8px',
              borderRadius: '4px'
            }
          }}
        />
        <div className="slider-label">
          {moment('2001-01-01').add(sliderValue, 'months').endOf('month').format('YYYY-MM-DD')}
        </div>
      </div>
    </div>
  );
};

export default EFlowStatusMap;
