import React from 'react';
import { useSearchParams } from 'react-router-dom';
import EFlowChartExport from './EflowChartExport'; // Adjust the import path if necessary

const EFlowChartSimplePage = () => {
  const [searchParams] = useSearchParams();
  const riverCode = searchParams.get('river_code') || "OLIF-B73C-MAMBA"; // Default value
  const startDate = searchParams.get('start_date') || "2023-10-01"; // Default value
  const endDate = searchParams.get('end_date') || "2024-12-31"; // Default value

  return (
    <div style={{ height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ height: '100%', width: '100%', position: 'relative' }}>
        <EFlowChartExport
          riverCode={riverCode}
          startDate={startDate}
          endDate={endDate}
        />
      </div>
      {/* Inline style override */}
      <style>
        {`
          .chart-container,
          .chart-container > div,
          .chart-container > div > div,
          .chart-container > div > div > div {
            height: 100% !important;
            width: 100% !important;
            max-height: none !important;
            max-width: none !important;
          }
        `}
      </style>
    </div>
  );
};

export default EFlowChartSimplePage;
