import React from 'react';
import { useSearchParams } from 'react-router-dom';
import RainfallChartExport from './RainfallChartExport'; // Adjust the import path if necessary

const SimpleRainfallChart = () => {
  const [searchParams] = useSearchParams();
  const stationName = searchParams.get('station_name') || "DefaultStationName"; // Provide a default value if needed
  const channelId = searchParams.get('channel_id') || "DefaultChannelId"; // Provide a default value if needed
  const startDate = searchParams.get('start_date') || "2023-01-01"; // Provide a default value if needed
  const endDate = searchParams.get('end_date') || "2023-12-31"; // Provide a default value if needed
  const typeFilter = searchParams.get('type_filter') || ""; // Provide a default value if needed

  return (
    <div style={{ height: '100vh', width: '100vw', margin: 0, padding: 0 }}>
      <RainfallChartExport
        stationName={stationName}
        channelId={channelId}
        startDate={startDate}
        endDate={endDate}
        typeFilter={typeFilter}
      />
      {/* Inline style override */}
      <style>
        {`
          /* Ensure body and html take full height and remove default margins */
          html, body, #root {
            height: 100%;
            width: 100%;
            margin: 0;
            padding: 0;
          }

          /* Override styles for chart container and its children */
          .chart-container,
          .chart-container > div,
          .chart-container > div > div,
          .chart-container > div > div > div,
          .recharts-wrapper {
            height: 100% !important;
            width: 100% !important;
            max-height: none !important;
            max-width: none !important;
          }

          /* Ensure the chart canvas fills the container */
          .recharts-surface {
            width: 100% !important;
            height: 100% !important;
          }

          /* Optional: Remove any padding or margins inside the chart */
          .chart-container {
            padding: 0 !important;
            margin: 0 !important;
          }
        `}
      </style>
    </div>
  );
};

export default SimpleRainfallChart;
